import _ from 'lodash';
import React, { useState } from "react"
import Select from 'react-select';
import { useIntl } from 'gatsby-plugin-intl';

import Footer from '../components/footer'
import Header from '../components/header'
import locationIcon from '../icons/lokacija.svg';
import phoneIcon from '../icons/telefon.svg';
import emailIcon from '../icons/mail.svg';
import hoursIcon from '../icons/radno_vrijeme.svg';
import favicon from '../icons/favicon.ico';
import { Helmet } from "react-helmet";
import Dropzone from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const categories = [
    { value: 'RESIDENTIAL', labelId: 'categories.residential' },
    { value: 'BUSINESS', labelId: 'categories.business' },
    { value: 'HOSPITALITY_FACILITIES', labelId: 'categories.hospitality' },
    { value: 'SACRAL', labelId: 'categories.sacral' }
];

const subcategories = {
    'RESIDENTIAL': [
        { value: 'KITCHEN', labelId: 'categories.kitchen' },
        { value: 'BATHROOM', labelId: 'categories.bathroom' },
        { value: 'CHILDRENS_ROOM', labelId: 'categories.childrenRoom' },
        { value: 'LIVING_ROOM', labelId: 'categories.livingRoom' },
        { value: 'BEDROOM', labelId: 'categories.bedroom' },
        { value: 'STUDY', labelId: 'categories.study' },
        { value: 'HALL', labelId: 'categories.hall' }
    ],
    'BUSINESS': [
        { value: 'SHOP', labelId: 'categories.shop' },
        { value: 'OFFICE', labelId: 'categories.office' },
        { value: 'SANITATION', labelId: 'categories.sanitation' }
    ],
    'HOSPITALITY_FACILITIES': [
        { value: 'ROOMS_APARTMANTS', labelId: 'categories.apartments' },
        { value: 'CATERING_FACILITIES', labelId: 'categories.catering' }
    ],
    'SACRAL': []
};

export default (props) => {
    const intl = useIntl();

    const lang = props.pathContext.language;

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [telephone, setTelephone] = useState();
    const [comment, setComment] = useState();
    const [category, setCategory] = useState();
    const [subcategory, setSubCategory] = useState();
    const [sent, setSent] = useState(false);

    const [selectedFile, setSelectedFile] = useState();

    const sendMessage = () => {

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('request', JSON.stringify({
            lang,
            name,
            email,
            telephone,
            comment,
            category: category && category.label,
            subcategory: subcategory && subcategory.label,
            reply: intl.formatMessage({ id: "contactPage.form.sent" }),
            fileName: selectedFile?.name
        }));

        fetch('https://nanonet.hr/api/meet.php?lang=' + lang, {
            method: 'POST',
            body: formData
        })
            .then(() => {
                setSent(true);
                setName();
                setEmail();
                setTelephone();
                setCategory();
                setSubCategory();
                setComment();
                setSelectedFile();
            });
    };

    return (
        <div className="contact">
            <Helmet>
                <meta property="og:image" content="https://nanonet.hr/cover.jpg"/>
                <meta property="og:image:secure_url" content="https://nanonet.hr/cover.jpg"/>
                <title>{intl.formatMessage({ id: "header.contact" })}</title>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Header lang={props.pathContext.language}/>

            <div className="contact-container">
                <div className="contact-info">
                    <div className="contact-info-item">
                        <div className="contact-icon">
                            <img alt="" src={locationIcon}/>
                        </div>
                        <div className="text">
                            {intl.formatMessage({ id: "contactPage.address.line1" })}<br/>
                            {intl.formatMessage({ id: "contactPage.address.line2" })}<br/>
                            {intl.formatMessage({ id: "contactPage.address.line3" })}<br/>
                        </div>
                    </div>
                    <div className="contact-info-item">
                        <div className="contact-icon">
                            <img alt="" src={phoneIcon}/>
                        </div>
                        <div className="text">
                            {intl.formatMessage({ id: "contactPage.telephoneNumber" })}<br/>
                            {intl.formatMessage({ id: "contactPage.faxNumber" })}<br/>
                        </div>
                    </div>
                    <div className="contact-info-item">
                        <div className="contact-icon">
                            <img alt="" src={emailIcon}/>
                        </div>
                        <div className="text">
                            info@nanonet.hr
                        </div>
                    </div>
                    <div className="contact-info-item">
                        <div className="contact-icon">
                            <img alt="" src={hoursIcon}/>
                        </div>
                        <div className="text">
                            08:00 - 18:00<br/>
                            {intl.formatMessage({ id: "contactPage.workingDays" })}
                        </div>
                    </div>
                </div>
                <div className="map-form-container">
                    <iframe width="100%"
                            height="300"
                            frameBorder="0"
                            style={{border:0}}
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJPeK4zoKtaEcRMRDWZ816gBs&key=AIzaSyB3xt8V-H6CY3qFW8joWyy1b8YA9zdTUEo"
                            allowFullScreen>
                    </iframe>
                    <div className="contact-form">
                        <h1>{intl.formatMessage({ id: "contactPage.form.title" })}</h1>

                        <div className="text label">{intl.formatMessage({ id: "contactPage.form.name" })}</div>
                        <input
                            className="text"
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />

                        <div className="text label">{intl.formatMessage({ id: "contactPage.form.email" })}</div>
                        <input
                            className="text"
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />

                        <div className="text label">{intl.formatMessage({ id: "contactPage.form.telephone" })}</div>
                        <input
                            className="text"
                            type="text"
                            value={telephone}
                            onChange={(event) => setTelephone(event.target.value)}
                        />

                        <div className="text label">{intl.formatMessage({ id: "contactPage.form.category" })}</div>
                        <Select
                            className="text"
                            classNamePrefix="contact"
                            value={category}
                            onChange={(opt) => {
                                setCategory(opt);
                                setSubCategory(null);
                            }}
                            placeholder={intl.formatMessage({ id: "contactPage.form.categoryPlaceholder" })}
                            options={_.map(categories, cat => ({
                                value: cat.value,
                                label: intl.formatMessage({ id: cat.labelId })
                            }))}
                        />

                        <div className="text label">{intl.formatMessage({ id: "contactPage.form.subcategory" })}</div>
                        <Select
                            className="text"
                            classNamePrefix="contact"
                            value={subcategory}
                            onChange={setSubCategory}
                            placeholder={intl.formatMessage({ id: "contactPage.form.subcategoryPlaceholder" })}
                            options={_.map(subcategories[category?.value] || [], sc => ({
                                value: sc.value,
                                label: intl.formatMessage({ id: sc.labelId })
                            }))}
                            noOptionsMessage={() => intl.formatMessage({ id: "contactPage.form.subcategoryEmpty" })}
                            disabled={_.isEmpty(subcategories[category?.value] || [])}
                        />

                        <div className="text label">{intl.formatMessage({ id: "contactPage.form.comment" })}</div>
                        <textarea
                            className="text"
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />

                        <Dropzone onDrop={acceptedFiles => setSelectedFile(acceptedFiles[0])}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>{selectedFile ? selectedFile?.name : intl.formatMessage({ id: "contactPage.form.upload" })}</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        {
                            !sent && (
                                <div className={"button-container " + ((!name || !email || !telephone) && "disabled")}>
                                    <button
                                        onClick={sendMessage}
                                        disabled={!name || !email || !telephone}
                                    >{intl.formatMessage({ id: "contactPage.form.send" })}
                                    </button>
                                </div>
                            )
                        }
                        {
                            sent && (
                                <div className="button-container sent">
                                    <button>{intl.formatMessage({ id: "contactPage.form.sent" })}</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
